import { datadogRum } from "@datadog/browser-rum";

export const init = (datadog: any) => {
  datadogRum.init({
    version: document
      .querySelector("meta[property='version']")
      ?.getAttribute("content"),
    ...datadog,
  });
  //  service: 'my-web-application',
  //  env: 'production',
};
