import rocket from './noodle-logo-color.svg';

const Home = () => {
  return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', flexGrow: 1, }}>
    <img src={rocket} width={560}/>
  </div>
}

export default function NoodleDashboard() {
  return <>
    <Home />
  </>
}
