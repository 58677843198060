import { gql, useQuery } from "@apollo/client";
import { IconButton, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { useDebounce } from "use-debounce";
import { useHistory } from "react-router-dom";

const searchQuery = gql`
  query SearchQuery($query: String!) {
    search(first: 100, query: $query) {
      nodes {
        __typename
        ...on Account {
          id
          displayName
          resourcePath
        }
        ...on Project {
          id
          displayName
          resourcePath
        }
        ...on PaymentProfile {
          id
          displayName
          resourcePath
        }
      }
    }
  }
`;


const toFriendlyName = (input: string) => {
  switch (input) {
    case 'Account':
      return 'Billing Account';
    case 'Project':
      return 'Tenant';
    case 'PaymentProfile':
      return 'Payment Profile';
    default:
      return input;
  }
}
interface SearchItem {
  __typename: string;
  id: string;
  displayName: string;
  resourcePath: string;
}

interface SearchResult {
  search: {
    nodes: SearchItem[]
  }
}

export default function Asynchronous({ className }: any): JSX.Element {

  const history = useHistory();

  const [inputValue, handleInputChange] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [stableInputValue] = useDebounce(inputValue, 250);

  //
  const { loading, data } = useQuery<SearchResult>(searchQuery, {
    variables: {
      query: stableInputValue,
    },
    skip: !(stableInputValue?.length > 0),
  });

  return (
    <Autocomplete
      freeSolo
      style={{ backgroundColor: 'white', paddingTop: 4, paddingBottom: 4, paddingLeft: 8, paddingRight: 8, }}
      selectOnFocus
      // clearOnBlur
      handleHomeEndKeys
      className={className}
      loading={loading}
      onChange={(e, val: any) => {
        if (!val) {
          return;
        }
        if (typeof val === "object") {
          history.push(`/${val?.resourcePath}`);
        } else {
          history.push(`/accounts?q=${val}`);
        }
        handleInputChange("");
        setOpen(false);
      }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option?.displayName ?? (typeof option === 'string' ? option : "<unknown>")}
      options={data?.search?.nodes ?? []}
      renderOption={(option: SearchItem) => {
        return (
          <Grid container alignItems="center">
            {/* <Grid item>
              xyz
            </Grid> */}
            <Grid item xs>
              <Typography variant="body2">{option.displayName} ({toFriendlyName(option.__typename)})</Typography>
            </Grid>
          </Grid>
        );
      }}
      value={value}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => handleInputChange(newInputValue)}
      renderInput={(params) => (
        <div ref={params.InputProps.ref}>
          <InputBase
            {...params.inputProps}
            placeholder="Search"
            fullWidth={true}
            // margin="dense"
            startAdornment={
              <InputAdornment position="start" variant="outlined">
                <SearchIcon />
                {/* { loading ? <CircularProgress color="inherit" /> : <SearchIcon /> } */}
              </InputAdornment>
            }
          // endAdornment={params.InputProps.endAdornment}
          />
        </div>
      )}
    />
  );
}
