import Button from "@material-ui/core/Button";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import logo from './noodle-logo-color.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: 0,
      padding: 0,
      margin: 0,
    },
    button: {
      border: 0,
      fontSize: 50,
      textAlign: "center",
    },
  })
);

export default function LoginPrompt({ onLogin }: any) : JSX.Element {

  const [activated, activate] = useState<boolean>(false);
  const classNames = useStyles();

  useEffect(() => {

    if (activated) {
      onLogin();
    }

  }, [ onLogin, activated ]);

 
  let message = (
    <>
      Yes, the elves went too far...
      <br />      
      <motion.div
        transition={{ duration: 1.5, delay: 2 }}
        initial={{ opacity: 0, scale: 1 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0 }}
      >
        <Button
          onClick={() => activate(true)}
          variant="contained"
          color="primary"
          disableElevation
          size="large"
        >
          Login
        </Button>
      </motion.div>
    </>
  );

  if (activated) {
    message = (
      <img
        alt="mmm, noodles"
        src={logo}
      />
    );
  }

  return (
    <div className={classNames.root}>
      <motion.div
        className={classNames.button}
        transition={{ duration: 1.5 }}
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0 }}
      >
        {message}
      </motion.div>
    </div>
  );
}
