import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import Auth0ProviderWithHistory from "./auth/Auth0Provider";
import { bootConfig } from "./boot/config";
import WelcomeScreen from "./boot/welcome";
import ThemeProvider from "./dressing/theme";
import "./index.css";
import NoodleNetworkProvider from './network/NetworkProvider';
import { IntlProvider } from 'react-intl';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const AppShell = ({ config }: any) => {

  const { isLoading, isAuthenticated, loginWithRedirect, user } = useAuth0();

  if (isLoading) {
    // initializing ...
    return <></>
  }
  else if (!isAuthenticated) {
    return <WelcomeScreen onLogin={loginWithRedirect} />
  }

  return (
    <IntlProvider locale="en-US">
      <ThemeProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <NoodleNetworkProvider config={config}>
            <App config={config} />
          </NoodleNetworkProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </IntlProvider>
  )
}

const bootup = async () => {
  const bootcfg = await bootConfig();
  ReactDOM.render(
    <React.StrictMode>
      <Router>
        <Auth0ProviderWithHistory config={bootcfg.oidc}>
          <AppShell config={bootcfg} />
        </Auth0ProviderWithHistory>
      </Router>
    </React.StrictMode>,
    document.getElementById("root")
  );
}

bootup();
