import Drawer from '@material-ui/core/Drawer';

export default function SidebarDrawer({ children, open: state, onClose }: any): JSX.Element {
  return (
    <Drawer anchor="left" open={state} onClose={onClose}>
      <div
        style={{ width: 320 }}
        role="presentation"
        onClick={onClose}
        onKeyDown={onClose}
      >
        {children}
      </div>
    </Drawer>
  );
}
