import { ApolloClient, ApolloLink, ApolloProvider, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { useAuth0 } from "@auth0/auth0-react";




const NoodleNetworkProvider = ({ config, children }: any) => {

  let token: string | null = null;

  const { getAccessTokenSilently } = useAuth0();

  const httpLink : ApolloLink = createHttpLink({ ...config.endpoint });

  const link = httpLink;


  const withToken = setContext(async () => {

    if (!token) {
      token = await getAccessTokenSilently();
    }

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };

  });

  //

  const resetToken = onError(({ networkError }: any) => {
    if (networkError && networkError.name === 'ServerError' && networkError.statusCode === 401) {
      // remove cached token on 401 from the server
      token = null;
    }
  });

  const authFlowLink = withToken.concat(resetToken);

  //

  const possibleTypes = {};

  const typePolicies: any = {
    Query: {
      fields: {
        viewer: {
        },
      },
    },
  };

  const client = new ApolloClient({
    link: authFlowLink.concat(link),
    cache: new InMemoryCache({ possibleTypes, typePolicies }),
  });

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  );

};

export default NoodleNetworkProvider;
