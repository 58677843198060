import { useEffect, useState } from "react";
import { BounceLoader } from 'react-spinners';

function LoadingIndicator() {
  return <div style={{ alignSelf: 'center', justifySelf: 'center', }}>
    <BounceLoader/>
  </div>
}

// delay showing a loading indicator for 250ms to avoid it showing in very short cases.
export default function LoadingIndicatorWithDelay() {
  const delay = 250;
  const [showLoadingIndicator, setLoadingIndicatorVisibility] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => setLoadingIndicatorVisibility(true), delay);
    return () => {
      clearTimeout(timer);
    };
  });
  return showLoadingIndicator ? <LoadingIndicator /> : <></>;
}

