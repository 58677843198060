import React, { Suspense, useState } from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "./components/dashboard/dashboard";
import AppBar from "./dressing/appbar";
import SidebarDrawer from "./dressing/sidebar";
import Sidebar from "./SideBar";
import LoadingIndicatorWithDelay from "./ui/LoadingIndicatorWithDelay";

const routes = {

  reports: { component: React.lazy(() => import("./components/reports")) },
  voiceops: { component: React.lazy(() => import("./components/voiceops")) },

  "invoices": { component: React.lazy(() => import("./components/invoices")) },
  "accounts": { component: React.lazy(() => import("./components/accounts")) },
  "projects": { component: React.lazy(() => import("./components/projects")) },

  //
  "orders": { component: React.lazy(() => import("./components/orders")) },
  "draftOrders": { component: React.lazy(() => import("./components/orders")) },

  "payments": { component: React.lazy(() => import("./components/payments")) },

  "skus": { component: React.lazy(() => import("./components/sku")) },
  "annotations": { component: React.lazy(() => import("./components/annotations/AnnotationsRoute")) },

  "views": { component: React.lazy(() => import("./registry/ui/ViewRoute")) },

};



function App({ config }: any): JSX.Element {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <AppBar onToggle={() => setSidebarOpen(true)}>
      <SidebarDrawer open={sidebarOpen} onClose={() => setSidebarOpen(false)}>
        <Sidebar />
      </SidebarDrawer>
      <div style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, overflow: 'scroll', display: 'flex', flexDirection: 'column', }}>
        <Suspense fallback={<LoadingIndicatorWithDelay />}>
          <Switch>
            <Route path="/" exact strict component={Dashboard} />
            {Object.entries(routes).map(([path, { component }]) => (<Route key={path} path={`/${path}`} strict component={component} />))}
            <Route>
              <div>invalid path</div>
            </Route>
          </Switch>
        </Suspense>
      </div>
    </AppBar>
  );
}

export default App;
