import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import { Link } from 'react-router-dom';

function TextLink({ text, to }: any) {
  const CustomLink = (props: any) => <Link to={to} {...props} />;
  return <ListItem button component={CustomLink}>
    <ListItemText primary={text} />
  </ListItem>
}

export default function Sidebar() {
  return <>
    <List>
      <TextLink text="Dashboard" to="/" />
      <TextLink text="Annotations" to="/annotations/" />
      <TextLink text="VoiceOps" to="/voiceops/" />
    </List>
    {/* <Divider /> */}
  </>
}