import React from "react";
import { useHistory } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";


const Auth0ProviderWithHistory = ({ config, children }: any) => {

  const domain = "auth.fluentstream.com";
  const clientId = config.client_id;

  const history = useHistory();

  const onRedirectCallback = (appState: any) => {
    history.push(appState?.returnTo || '/');
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={`${window.location.origin}/-/auth/callback`}
      onRedirectCallback={onRedirectCallback}
      scope={config.scopes.join(' ')}
      audience={config.aud}
      connection="employee"
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;