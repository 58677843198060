import { init as datadogInit } from "./datadog";

const partition = () => {
  const url = new URL(window.location.origin);
  switch (url.hostname.toLowerCase()) {
    case "localhost.noodle.works":
      return "localhost";
    case "localhost":
    default:
      return "production";
  }
};

export async function bootConfig() {
  const config = (await import(`../appconfig.${partition()}.json`)).default;
  if (config.datadog) {
    datadogInit(config.datadog);
  }
  return config;
}
