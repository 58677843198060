import React from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import green from "@material-ui/core/colors/green";

const theme = createMuiTheme({
  palette: {

    primary: {
      light: "red",
      dark: "red",
      contrastText: "rgba(255, 255, 255, 1)",
      main: "rgb(80, 158, 227)",
    },

    secondary: {
      main: green[500],
      // contrastText: 'red',
      // light: 'red',
      // dark: 'red',
    },

    text: {
      // primary: 'white',
      // secondary: 'white',
      // disabled: 'white',
      // hint: 'white',
      // primary: 'rgba(0, 0, 0, 0.87)',
      // secondary: 'rgba(255, 255, 255, 0.54)',
      // disabled: 'rgba(255, 255, 255, 0.38)',
      // hint: 'rgba(255, 255, 255, 0.38)',
    },

    background: {
      paper: "#fff",
      default: "#fafafa",
    },
  },

  typography: {
    fontSize: 14,
    // fontSize: '1.2rem',
    // '@media (min-width:600px)': {
    //   fontSize: '1.5rem',
    // },
    // [theme.breakpoints.up('md')]: {
    //   fontSize: '2.4rem',
    // },
    fontFamily: [
      // 'Rubik',
      // 'Karla',
      'Work Sans',
      // 'Roboto',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },

  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [],
      },
    },
  },
});

//

export default ({ children }: any) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);
