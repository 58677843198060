import { useAuth0 } from "@auth0/auth0-react";
import { Avatar } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import React, { forwardRef } from "react";

export default function Dropdown({ serverVersion }: any) {

  const { user } = useAuth0();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const RenderMenu = forwardRef((props, ref) =>
    <Menu
      ref={ref}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem>Server Version: {serverVersion}</MenuItem>
    </Menu>
  );

  return (
    <>
      <IconButton edge="end" aria-label="account of current user" aria-haspopup="true" onClick={handleProfileMenuOpen} color="inherit">
        <Avatar style={{ width: 36, height: 36 }} alt={user?.name} src={user?.picture} imgProps={{ unselectable: 'on' }} />
      </IconButton>
      <RenderMenu/>
    </>
  );
}

