import { gql, useQuery } from "@apollo/client";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsNoneTwoToneIcon from "@material-ui/icons/NotificationsNoneTwoTone";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useHistory } from "react-router-dom";
import Dropdown from "./dropdown";
import ErrorView from "./error/ErrorView";
import SearchBox from "./searchbox";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      justifyContent: "space-between",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      cursor: "pointer",
    },
    searchbox: {
      maxWidth: 600,
      flexGrow: 1,
    },
    end: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-end",
      justifyContent: "flex-end",
    },
  })
);

interface Props {
  onToggle: () => void;
  children: any;
}


function Tasks() {
  return (
    <IconButton>
      <NotificationsNoneTwoToneIcon style={{ color: "white" }} />
    </IconButton>
  );
}

export default function NoodleAppBar(props: Props) {

  const classes = useStyles();
  const history = useHistory();
  const handleClick = () => history.push("/");

  const { loading, error, data, networkStatus } = useQuery(
    gql`
      query ServerInfo {
        meta {
          server {
            version
            image
          }
        }
      }
    `
  );

  return (
    <>
      <CssBaseline />
      <AppBar elevation={0}>
        <Toolbar variant="regular">
          <IconButton color="inherit" aria-label="open drawer" onClick={props.onToggle} edge="start">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title} onClick={handleClick}>Noodle</Typography>
          <SearchBox className={classes.searchbox} />
          <div className={classes.end}>
            {/* <Tasks /> */}
            <Dropdown serverVersion={(data?.meta?.server?.image ?? "unknown") + "/" + (data?.meta?.server?.version ?? "unknown")} />
          </div>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Box my={0} style={{ display: 'flex', flexDirection: 'column', flex: 1, position: 'relative', }}>
        <ErrorBoundary FallbackComponent={ErrorView}>
          {props.children}
        </ErrorBoundary>
      </Box>
    </>
  );
}
