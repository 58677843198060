import { Button, Typography } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import { useState } from 'react';
import errorSVG from './error.svg';

export default function ErrorView({ error }: any) {
  const [expanded, setExpanded] = useState(false);
  return <div style={{ textAlign: 'center', }}>
    <img src={errorSVG} width={360} style={{ margin: 48 }} />
    <Typography variant="h4" style={{ margin: 16 }}>Oops!</Typography>
    <Typography variant="h5">Something went wrong.</Typography>
    <div style={{ margin: 'auto' }}>
      {!expanded && <Button variant="text" disableRipple onClick={() => setExpanded(true)} aria-label="Show more">Show Details</Button>}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Typography variant="body1" style={{ width: 900, textAlign: 'left', margin: 48 }}>
          {error?.message}
        </Typography>
      </Collapse>
    </div>
  </div>
}